<template>
  <div
    ref="parallaxContainer"
    class="w-full relative overflow-hidden"
  >
    <div
      ref="parallaxBanner"
      class="w-full absolute left-1/2 -translate-x-1/2"
      :style="`height: ${heightStyle}; top: ${topStyle}`"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  type PropTypes = {
    speed: number
  }
  const props: PropTypes = defineProps<PropTypes>()

  const parallaxContainer = ref<HTMLDivElement | null>( null )
  const parallaxBanner = ref<HTMLDivElement | null>( null )

  const height = 100 * ( 1 + ( 2 * props.speed ) )
  const heightStyle = height.toFixed( 0 ) + "%"
  const top = -100 * 2 * props.speed
  const topStyle = top.toFixed( 0 ) + "%"

  // Parallax scroll handler
  const handleScroll = () => {
    if ( parallaxContainer.value && parallaxBanner.value ) {
      const viewportHeight = document.documentElement.clientHeight
      const containerHeight = parallaxContainer.value.offsetHeight
      const containerOffset = parallaxContainer.value.offsetTop
      const bannerHeight = parallaxBanner.value.offsetHeight
      const scrollPosition = window.scrollY

      const minScroll = containerOffset - viewportHeight
      const maxScroll = containerOffset + containerHeight
      const scrollRange = maxScroll - minScroll
      const scrollFraction = ( scrollPosition - minScroll ) / scrollRange

      const transform = ( ( bannerHeight - containerHeight ) * scrollFraction ).toFixed( 0 )
      parallaxBanner.value.style.transform = `translate(-50%, ${transform}px)`
    }
  }

  // Register scroll event when the component is mounted
  onMounted( () => {
    window.addEventListener( "scroll", handleScroll )
  } )

  // Cleanup event listener when component is unmounted
  onBeforeUnmount( () => {
    window.removeEventListener( "scroll", handleScroll )
  } )
</script>
